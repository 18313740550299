import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw, NavigationGuardNext, RouteLocationNormalizedLoaded, RouteLocationNormalized } from 'vue-router';
import { socket, CredentialManager } from "./../globals.js"

const options = {
  checkValidationAndRedirect: (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
    // Check if the user is verified
    if (!window.isVerified) {
      // If not verified, redirect to the login page
      next({ path: "/login" });
    } else {
      // If verified, allow the navigation
      next();
    }
  },
  checkNotValidAndRedirect: (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
    // Check if the user is verified
    console.log(window.isVerified)
    if (window.isVerified) {
      // If verified and not navigating to the home route, redirect to the general room
      next({ name: "Room", params: { roomId: "general" } });
    } else {
      // If not verified, allow the navigation
      next();
    }
  },
};
const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/folder/:id',
    component: () => import('../views/FolderPage.vue'),
    beforeEnter: options.checkNotValidAndRedirect
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
    beforeEnter: options.checkNotValidAndRedirect
  },
  {
    path: '/signup',
    component: () => import('../views/Signup.vue'),
    beforeEnter: options.checkNotValidAndRedirect

  }, {
    path: '/chat',
    component: () => import('../views/Chat.vue'),
    beforeEnter: options.checkNotValidAndRedirect
  },
  {
    path: '/map',
    component: () => import('../views/Map.vue'),
  },
  {
    path: '/logout',
    redirect(to) {
      CredentialManager.removeData();
      return '/login';
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
      // Check if the user is verified
      if (!window.isVerified) {
        // If not verified, redirect to the login page
        next({ path: from.path });
      } else {
        CredentialManager.removeData();
        next({path:"/login"});
      }
    },
  },
  {
    path: '/destroyaccount',
    redirect: '/login',
    beforeEnter: options.checkNotValidAndRedirect
  },
  {
    path: '/datadeletion',
    redirect: '/login',
    beforeEnter: options.checkNotValidAndRedirect
  },
  {
    path: '/chapter/:chapterid/chat/:chatid',
    component: () => import('../views/Chat.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/login',
  },
  {
    path: '/loading',
    component: () => import('../views/Loading.vue')
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})
console.log(router.getRoutes())
export default router
