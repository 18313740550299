<template>
    <ion-page v-if="!(clientCheck && updateCheck && socketCheck)">
        <ion-content class="ion-padding" style="align-items: -webkit-center; text-align: -webkit-center;top:25%">
            <div
                style="min-height: 256px; position: relative; display: flex; flex-direction: column; align-items: center;">
                <ion-spinner color="warning"
                    style="height: 150px; position: absolute; width: 150px;z-index:1"></ion-spinner>
                <img style="margin-top: 5px; width: 135px; height: 135px; border-radius: 100px; z-index:0"
                    src="./../../resources/animation.gif">
            </div>

            <Transition name="fade">

                <div style="margin-top:20px;font-size: x-large;" v-if="!updateCheck">{{ loadingText.updates }}</div>
            </Transition>
            <Transition name="fade">
                <div style="margin-top:20px;font-size: x-large;" v-if="!clientCheck">{{ loadingText.client }}</div>
            </Transition>
            <Transition name="fade">
                <div style="margin-top:20px;font-size: x-large;" v-if="!socketCheck">{{ loadingText.socket }}</div>
            </Transition>
        </ion-content>
    </ion-page>

</template>

<script setup lang="ts">

import { Device } from '@capacitor/device';
import ToolBar from "../components/ToolBar.vue";
import { computed, ref } from "vue";
import {
    IonPage,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonSpinner,
    IonButtons,
    TransitionOptions,
    IonSelectOption,
    IonMenuButton,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonTitle,
    IonRow,
    IonCol,
    IonInput,
    IonText,
    IonToast,
    IonSelect,
    IonRadioGroup,
    IonRadio
} from "@ionic/vue";

const showToast = ref(false);
const toastMessage = ref("");

// Extend the Window interface to include the properties


// Extend the Window interface to include the properties


const clientCheck = ref(false);
const updateCheck = ref(false);
const socketCheck = ref(false);

let int = setInterval(() => {
    socketCheck.value = socket.socket.connected;
    updateCheck.value=true
}, 100);
Device.getId().then((info) => {
    clientCheck.value = true;
});   
</script>
<script lang="ts">
import * as outlines from 'ionicons/icons';
import { socket } from '@/globals';
let dotCount = 0;
const maxDots = 3;
const loadingText = ref({
    updates: "Checking for updates",
    client: "Initializing Client",
    socket: "Awaiting Socket"

});

const updateLoadingText = () => {
    dotCount = (dotCount + 1) % (maxDots + 1);
    loadingText.value.updates = "Checking For Updates" + ".".repeat(dotCount);
    loadingText.value.client = "Initializing Client" + ".".repeat(dotCount);
    loadingText.value.socket = "Awaiting Socket" + ".".repeat(dotCount);
};

let intervalId: any;

export default {
    components: {
        ToolBar
    },
    mounted() {
        intervalId = window.setInterval(updateLoadingText, 500);
    },

    unmounted: () => {
        window.clearInterval(intervalId);
    }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>