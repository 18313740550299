// src/backgroundTask.ts
import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
const { BackgroundFetch } = Plugins;

export const startBackgroundTask = () => {
    if (Capacitor.isNativePlatform()) {
        // Use BackgroundFetch for native platforms
        BackgroundFetch.configure({
            minimumFetchInterval: 15, // Fetch interval in minutes
        }, async (taskId: string) => {
 
            // Must call finish() when your task is complete
            BackgroundFetch.finish(taskId);
        }, (error: string) => {
            console.error('Background fetch failed', error);
        });
    } else {
        // Use setInterval for web
        setInterval(async () => {

        }, 1000);
    }
};