<template>
  <Transition name="fade">
    <Loading style="z-index: 109000;" ></Loading>
  </Transition>
  <ion-app v-if="!LoadingRef">


    <ion-menu menu-id="nav" content-id="main-content" type="push">

      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header>Welcome Back!</ion-list-header>
          <ion-note>{{ globalUser ? globalUser.getUsername() : "" }}</ion-note>
          <div v-for="(p, i) in appPages">
            <ion-menu-toggle menu="nav" :auto-hide="false" v-if="p.showIf()" :key="i">
              <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none"
                :detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                <ion-icon aria-hidden="true" slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
              <br v-if="appPages.length == i + 1">
            </ion-menu-toggle>
          </div>
          <div v-if="isVerified">
            <ion-list-header>Rooms</ion-list-header>
            <ion-menu-toggle :auto-hide="false" v-for="(p, i) in rooms" :v-if="p.showIf()" :key="i">
              <ion-item router-direction="root" :router-link="p.url" lines="none" :detail="false" class="hydrated">
                <ion-icon aria-hidden="true" slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
          <div v-if="isVerified" style="position: relative;">
            <ion-list-header>User Options</ion-list-header>
            <ion-menu-toggle :auto-hide="false" v-for="(p, i) in userOptions" :v-if="p.showIf()" :key="i">
              <ion-item router-direction="root" :router-link="p.url" lines="none" :detail="false" class="hydrated">
                <ion-icon aria-hidden="true" slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
        </ion-list>


      </ion-content>
    </ion-menu>
    <ion-menu v-if="isVerified" side="end" menu-id="users" content-id="main-content" type="push">

      <ion-content>
        <ion-list id="user-list">
          <ion-list-header>User List - Chapter {{ globalChapter == undefined ? "" : globalChapter.getId() }}</ion-list-header>
          <ion-note v-if="globalUser != undefined">{{ globalUser?.getUsername() }}</ion-note>
          <div v-if="globalUsers.filter((e: UserOBJ) => e.hasFlag(Flags.CHAPTER_ADVISOR)).length > 0">
            <ion-list-header>Advisors</ion-list-header>
            <ion-menu-toggle menu="users" :auto-hide="false"
              v-for="(p, i) in globalUsers.filter((e: UserOBJ) => e.hasFlag(Flags.CHAPTER_ADVISOR))" :key="i">
              <User :p="p" :i="i"></User>
              <br>

            </ion-menu-toggle>
          </div>
          <div v-if="globalUsers.filter((e: UserOBJ) => e.hasFlag(Flags.CHAPTER_ADMIN)).length > 0">
            <ion-list-header>Admins</ion-list-header>
            <ion-menu-toggle menu="users" :auto-hide="false"
              v-for="(p, i) in globalUsers.filter((e: UserOBJ) => e.hasFlag(Flags.CHAPTER_ADMIN))" :key="i">
              <User :p="p" :i="i"></User>
              <br>

            </ion-menu-toggle>
          </div>
          <div v-if="globalUsers.filter((e: UserOBJ) => e.hasFlag(Flags.CHAPTER_MEMBER)).length > 0">
            <ion-list-header>Members</ion-list-header>
            <ion-menu-toggle menu="users" :auto-hide="false"
              v-for="(p, i) in globalUsers.filter((e: UserOBJ) => e.hasFlag(Flags.CHAPTER_MEMBER))" :key="i">
              <User :p="p" :i="i"></User>
              <br>

            </ion-menu-toggle>
          </div>
          <div v-if="globalChapter != undefined" v-for="(role, i) in globalChapter.getRoles()">
            <ion-list-header>{{ role.getRoleName() }}</ion-list-header><ion-menu-toggle menu="users" :auto-hide="false"
              v-for="(p, i) in globalUsers.filter((e: UserOBJ) => e.getRoles()[0].getRoleId() == role.getRoleId())" :key="i">
              <User :p="p" :i="i"></User>
              <br>

            </ion-menu-toggle>

          </div>

          <ion-list-header>Rooms</ion-list-header>

        </ion-list>


      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-app>
</template>

<script setup lang="ts">
import { CredentialManager, globalUser, globalChapter, globalUsers, socket } from "./globals"
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonSpinner,
  IonLabel,
  IonList,
  IonListHeader,
  IonButton,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,

} from '@ionic/vue';
import { ref } from 'vue';
import * as outlines from 'ionicons/icons';



</script>
<script lang="ts">
import { Device, DeviceInfo } from "@capacitor/device"
import User from './components/User.vue'
import Loading from './views/Loading.vue'
import { User as UserOBJ, Chapter, Role, UserProfile, Flags, } from "chaptercoreapi";
declare global {
  interface Window { device: DeviceInfo; isVerified: boolean }
}
var appPages: { title: string, url: string, iosIcon: string, mdIcon: string, showIf: () => boolean }[]

var selectedIndex = ref(0);
var LoadingRef = ref(true)
setInterval(() => {
  LoadingRef.value = !socket.socket.connected
  console.log("verified: ",window.isVerified)
}, 1000)




export default {
  data() {
    return {
      username: undefined,
      type: "push",
      isVerified: window.isVerified,
      userOptions: [
        {
          title: 'Profile',
          url: '/profile',
          iosIcon: outlines.personOutline,
          mdIcon: outlines.personOutline,
          showIf: () =>  window.isVerified
        },
        {
          title: 'Settings',
          url: '/settings',
          iosIcon: outlines.settingsOutline,
          mdIcon: outlines.settingsOutline,
          showIf: () =>  window.isVerified
        },
        {
          title: 'Logout',
          url: '/logout',
          iosIcon: outlines.logOutOutline,
          mdIcon: outlines.logOutOutline,
          showIf: () =>  window.isVerified
        }
      ],
      rooms: [
        {
          title: 'Chat',
          url: '/chat',
          iosIcon: outlines.sendOutline,
          mdIcon: outlines.sendOutline,
          showIf: () =>  false
        }
      ]
    }
  },

  components: {
    User,
    Loading
  },
  created() {
    appPages = [
      {
        title: 'Login',
        url: '/login',
        iosIcon: outlines.logInOutline,
        mdIcon: outlines.logInOutline,
        showIf: () =>  !window.isVerified
      }, {
        title: 'Signup',
        url: '/signup',
        iosIcon: outlines.createOutline,
        mdIcon: outlines.createOutline,
        showIf: () =>  !window.isVerified
      },


    ];

    const path = window.location.pathname.split('/')[1];
    console.log(path)
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex((page) => page.title.toLowerCase() === path.toLowerCase());
    }
  },

  async beforeMount() {
    await Device.getInfo().then((info) => {
      window.device = info
    });

    while (!window.device.operatingSystem)
      if (['Win32', 'Win64', 'Windows', 'WinCE'].includes(window.device.operatingSystem)) this.type = "overlay"
      else this.type = "push"
  }

}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list,
ion-menu.md ion-list#user-list {
  border-bottom: 1px solid var(--ion-background-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header,
ion-menu.md ion-list#user-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
