import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { CredentialManager, playSound, sendNotification, socket } from "./globals";
import { IonicVue } from '@ionic/vue';
import { startBackgroundTask } from './assets/runner'
let tempconsole = console
const consoleOverride = {} as Console;
consoleOverride.log = function (...msg:Object[]) { tempconsole.debug(...msg) };  // This will suppress the logs
consoleOverride.warn = tempconsole.warn
consoleOverride.error = tempconsole.error
consoleOverride.info = tempconsole.info
consoleOverride.trace = tempconsole.trace
// Override the global console object with the new suppressed version
window.console = consoleOverride;

socket.socket.on("connect_error", (err) => {
  console.error("Socket connection error:", err);
});

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import '@ionic/vue/css/palettes/dark.always.css'; */
/* @import '@ionic/vue/css/palettes/dark.class.css'; */
import '@ionic/vue/css/palettes/dark.system.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue)
  .use(router);

router.isReady().then(() => {
  app.mount('#app');
  startBackgroundTask()
});
